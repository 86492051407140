<template>
  <div :class="{ isInStore: isInStore, isMobile: isMobile() }" class="v-root" v-if="show">
    <form id="shopping-cart-form" enctype="multipart/form-data">
      <input name="__RequestVerificationToken" type="hidden" />


      <!-- use the modal component, pass in the prop -->
      <transition name="modal">
        <Modal v-if="showModal" @close="showModal = false">
          <!--
  you can use custom content here to overwrite
  default content
-->
          <template v-slot:header>
            <h2>Beräkna bästa pris</h2>
            <span style="cursor: pointer" v-on:click="showModal = false">
              Tillbaka till kassan <i style="font-size: 18px; margin-left: 4px;" class="fa fa-times"></i>
            </span>
          </template>
          <template v-slot:body>
            <ShoppingCartCalculator :inCart="[...cart]" :inExcludeTax="excludeTax">
            </ShoppingCartCalculator>
          </template>
        </Modal>
      </transition>
      <div class="flex-just-center">
        <div>
          <table class="
                ui
                sortable
                compact
                celled
                table
                small
                tablet
                stackable
                product-table
              ">
            <thead>
              <tr v-if="!isMobile()">
                <th class="no-sort"></th>
                <th>Produkt</th>
                <th>Artikelnr</th>
                <th>Period</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Antal produkter</th>
              </tr>
            </thead>
            <tbody>
              <CartItemTableRow v-for="item in cart" :key="item.cartItem.Id" :item="item" :removeItem="removeFromCart"
                :quantityChange="quantityChangeItem" :excludeTax="excludeTax" />
              <tr class="empty-cart">
                <td colspan="5">
                  <span style="color: #f18a65">Du skickar eller lämnar tillbaka sakerna när du är klar. Så
                    du behöver inte välja slutdatum för din hyra</span>
                </td>
                <td colspan="2" class="right aligned">
                  <input type="button" style="background-color: white; color: #03564a" name="emptyCart"
                    value="Töm varukorgen" class="
                        button-rentor-second
                        remove-all-button
                        update-cart-button
                      " @click="removeAllFromCart" onclick="removeAllInCart()" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="summary-container">
          <div class="flex-just-between flex-items-end">
            <!-- <h2>Totalkostnad</h2> -->

          </div>
          <table class="ui table summary small unstackable" style="min-width: 320px"
            :style="{ 'margin-top': isMobile() ? 'initial' : '0' }">
            <tbody>
              <!-- <tr>
                <td>
                  Klicka på miniräknaren för att visa en detaljerad
                  priskalkylator
                </td>
                <td class="right aligned">
                  <i
                    id="show-modal"
                    @click="showModal = true"
                    style="font-size: 22px; color: #f18a65; cursor: pointer"
                    class="fa fa-calculator"
                  ></i>
                </td>
              </tr> -->
              <tr class="main-row">
                <td class="cart-total-left top aligned">
                  <label>Totalkostnad hyra</label>
                  <br />
                  <small><i>{{ excludeTax ? "exk moms" : "inkl moms" }}</i></small>
                </td>
                <td class="cart-total-right right aligned" v-if="!endDate">
                  <span class="value-summary one-row-force">{{
                    Math.round(
                      orderTotals.rentalTotalPerDayExTaxes +
                      (excludeTax ? 0 : orderTotals.taxRentalPerDay)
                    )
                  }}
                    kr / {{ allLongTermPeriodReadValue }}</span><br />
                  <i v-if="!isAllRentalSamePeriod" style="font-size: 12px">
                    Priset visas per dag då alla hyresobjekt inte är valda på
                    samma period
                  </i>
                </td>
                <td class="cart-total-right right aligned" v-else>
                  <span class="value-summary one-row-force">{{
                    bestPrice.totalPrice
                  }}
                    kr ({{ bestPrice.text }})</span><br />
                  <!-- <i v-if="!isAllRentalSamePeriod" style="font-size: 12px">
                    Priset visas per dag då alla hyresobjekt inte är valda på
                    samma period
                  </i> -->
                </td>
                
              </tr>

              <tr class="summary-details" :class="{ show: showRentalDetails }">
                <td colspan="2">
                  <table>
                    <tbody>
                      <tr class="summary-cost">
                        <td class="cart-total-left">
                          <label>Ordersumma hyresartiklar:</label>
                        </td>
                        <td class="right aligned one-row-force" v-if="!endDate">
                          {{
                            orderTotals.rentalTotalPerDayExTaxes +
                            (excludeTax ? 0 : orderTotals.taxRentalPerDay)
                          }}
                          kr / {{ allLongTermPeriodReadValue }}
                        </td>
                        <td class="right aligned one-row-force" v-else>
                          {{
                            bestPrice.totalPrice
                          }}
                          kr
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>{{ excludeTaxText }} hyresartiklar:</label>
                        </td>
                        <td class="right aligned" v-if="!endDate">
                          {{ orderTotals.taxRentalPerDay }} kr /
                          {{ allLongTermPeriodReadValue }}
                        </td>
                          <td class="right aligned" v-else>
                          {{ excludeTax ? (bestPrice.totalPrice * 0.25) :(bestPrice.totalPrice * 0.2) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr class="show-more-row">
                <td colspan="2">
                  <div @click="showRentalDetails = !showRentalDetails">
                    {{ showRentalDetails ? "Dölj" : "Visa" }} detaljer
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="order-subtotal main-row">
                <td>
                  <label>Engångskostnader</label>
                  <br />
                  <small><i>{{ excludeTax ? "exk moms" : "inkl moms" }}</i></small>
                </td>
                <td class="right aligned">
                  {{
                    Math.round(
                      orderTotals.salesTotalExTaxes +
                      (excludeTax ? 0 : orderTotals.taxSale)
                    )
                  }}
                  kr
                </td>
              </tr>

              <tr class="summary-details" :class="{ show: showSaleDetails }">
                <td colspan="2">
                  <table>
                    <tbody>
                      <tr class="summary-cost">
                        <td class="cart-total-left">
                          <label>Ordersumma försäljningsartiklar:</label>
                        </td>
                        <td class="cart-total-right right aligned one-row-force">
                          <span class="value-summary">{{
                            orderTotals.salesTotalExTaxes +
                            (excludeTax ? 0 : orderTotals.taxSale)
                          }}
                            kr</span>
                        </td>
                      </tr>
                      <tr class="tax-value">
                        <td class="cart-total-left">
                          <label>{{ excludeTaxText }} försäljningsartiklar:</label>
                        </td>
                        <td class="cart-total-right right aligned one-row-force">
                          <span class="value-summary">{{ orderTotals.taxSale }} kr</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr class="show-more-row">
                <td colspan="2">
                  <div @click="showSaleDetails = !showSaleDetails">
                    {{ showSaleDetails ? "Dölj" : "Visa" }} detaljer
                  </div>
                </td>
              </tr>
              <tr class="shipping-cost">
                <td class="cart-total-left">
                  <label>Frakt:</label>
                  <br />
                  <small class="selected-shipping-method">{{ selectedShippingMethod }}<br /><i>inkl moms</i></small>
                </td>
                <td class="cart-total-right one-row-force right aligned">
                  <span class="value-summary">{{ Math.round(shippingCost * 1.25) }} kr</span>
                  <div>
                    <small><i>Varav moms {{ Math.round(shippingCost * 0.25) }}</i></small>
                  </div>
                </td>
              </tr>
              <tr class="summary-cost-min-deb" style="font-size: 0.8rem">
                <td class="cart-total-left">
                  <label>Minsta totala debitering ink moms</label>
                </td>
                <td class="cart-total-right right aligned one-row-force">
                  <span class="value-summary">{{ orderTotals.rentalTotalMinDebIncTaxes }} kr</span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ShareCart :cart="cart"></ShareCart>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="checkout-attributes">
            <dl>
              <dt id="order_comment_label">
                <label class="text-prompt"> Orderkommentar (frivillig) </label>
              </dt>
              <dd id="order_comment_input_2">
                <textarea id="order_comment" name="order_comment" v-model="orderComment"></textarea>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="flex-just-center date-container">
        <div class="global-date-rental-container if-public">
          <label>Startdatum
            <i id="tooltiprental" class="fa fa-question-circle-o" title="placeholdertitle"></i></label>

          <Datepicker :clearable="false" locale="sv-SE" cancelText="Avbryt" selectText="Välj" autoApply noToday
            :format="'yyyy-MM-dd'" :minDate="fromDate" :enableTimePicker="false" :modelValue="startDate"
            @update:modelValue="setStartDates" />
        </div>

        <div class="global-date-rental-container if-public">
          <label>
            Uppskattat slutdatum (frivilligt)

          </label>
          <div>

          </div>
          <Datepicker :clearable="true" locale="sv-SE" cancelText="Avbryt" selectText="Välj" autoApply noToday
            :format="'yyyy-MM-dd'" :minDate="minEndDate" :modelValue="endDate" @update:modelValue="setEndDates" />
        </div>
      </div>
      <TaxSelectorFlyout v-on:taxchangeflyout="taxIsChanged" :excludeTax="excludeTax" v-if="cart.length && !showModal">
      </TaxSelectorFlyout>
      <div v-if="cart.length && !isInStore && showShippingOptions" style="text-align: center">

        <CheckoutShippingFlyout :onShippingChanged="onShippingChanged" :externalShippingCost="externalShippingCost"
          :selectedShippingMethod="fullshippingMethod" :excludeTax="excludeTax"
          :isOfferableShipping="externalShippingCost < 1" :sthlmIsOpen="stockholmIsOpen"
          :PickUpPointIsSelectedAndLocked="shippingSelectionDisabled"></CheckoutShippingFlyout>
      </div>
      <div class="ui form edit-address" v-if="showShippingOptions && excludeTax && !isSigning">
     

        <div class="field">
        <label for="BillingNewAddress_OrgNumber">Organisationsnummer:</label>
        <input type="text" id="BillingNewAddress_OrgNumber" name="BillingNewAddress_OrgNumber" placeholder="550102-0304" required
          v-model="inputValueIdentity" @input="handleChangeIdentity" />
        <span class="nop-required"></span>
        <span class="ui pointing red basic label" id="OrgNumber-error"></span>
      </div>
      <div class="field">
        <label for="BillingNewAddress_Company">Namn:</label>
        <input type="text" id="BillingNewAddress_Company" name="BillingNewAddress_Company"
          v-model="formValues.company" @input="handleChange('company')" />
        <span class="nop-required"></span>
        <span class="ui pointing red basic label" id="OrgNumber-error"></span>
      </div>
      <div class="data-getters">

        <div class="field">
          <label for="BillingNewAddress_Address1">Adress:</label>
          <input type="text" id="BillingNewAddress_Address1" name="BillingNewAddress_Address1" required
          v-model="formValues.address" @input="handleChange('address')" />
        <span class="ui pointing red basic label" id="Address1-error"></span>
      </div>
      <div class="two fields">
        <div class="field">
          <label for="BillingNewAddress_ZipPostalCode">Postkod:</label>
          <input type="text" id="BillingNewAddress_ZipPostalCode" name="BillingNewAddress_ZipPostalCode" required
          v-model="formValues.zipPostalCode" @input="handleChange('zipPostalCode')" />
          <span class="ui pointing red basic label" id="ZipPostalCode-error"></span>
        </div>
        <div class="field">
          <label for="BillingNewAddress_City">Postort:</label>
          <input type="text" id="BillingNewAddress_City" name="BillingNewAddress_City" required
          v-model="formValues.city" @input="handleChange('city')" />
          <span class="ui pointing red basic label" id="City-error"></span>
        </div>
      </div>
    </div>
      <div class="field">
        <label for="BillingNewAddress_EmailBilling">E-post fakturering:</label>
        <input type="email" id="BillingNewAddress_EmailBilling" name="BillingNewAddress_EmailBilling"
          class="border-color-grey" v-model="formValues.emailBilling" @input="handleChange('emailBilling')" :class="{ 'red-border': hasTouchedEmail && isEmailInvalid }" />
        <span class="ui pointing red basic label" id="EmailBilling-error"></span>
      </div>
        <div class="field">
          <label for="BillingNewAddress_Mark">Fakturamärkning (frivilligt):</label>
          <input type="text" id="BillingNewAddress_Mark" name="BillingNewAddress_Mark" required
            v-model="formValues.mark" @input="handleChange('mark')" />
          <span class="ui pointing red basic label" id="Mark-error"></span>
        </div>
        <br>
      <h3>Beställare</h3>
        <hr>
        <br>
        <div class="field">
          <label for="pnr_company_orderer">Personnummer:</label>
          <input type="text" id="pnr_company_orderer" pattern="(19|20)\d{6}-\d{4}" placeholder="19840101-5574" name="pnr_company_orderer" required
            v-model="inputValueIdentityPnrOrderer" @input="handleChangeIdentityPnrForOreder"/>
          <span class="nop-required"></span>
          <span class="ui pointing red basic label" id="pnr_company_orderer-error"></span>
        </div>
        <div class="two fields">
          <div class="field">
            <label for="BillingNewAddress_FirstName">Förnamn:</label>
            <input type="text" id="BillingNewAddress_FirstName"  v-model="formValues.firstName" @input="handleChange('firstName')" name="BillingNewAddress_FirstName" required :disabled="disableFirstnameLastName" />
            <span class="nop-required"></span>
            <span class="ui pointing red basic label" id="FirstName-error"></span>
          </div>
          <div class="field">
            <label for="BillingNewAddress_LastName">Efternamn:</label>
            <input type="text" id="BillingNewAddress_LastName" name="BillingNewAddress_LastName" @input="handleChange('lastName')"  v-model="formValues.lastName" required :disabled="disableFirstnameLastName" />
            <span class="nop-required"></span>
            <span class="ui pointing red basic label" id="LastName-error"></span>
          </div>
        </div>
        <div class="field">
          <label for="BillingNewAddress_Email">E-post:</label>
          <input type="email" id="BillingNewAddress_Email" name="BillingNewAddress_Email" @input="handleChange('email')"  
          v-model="formValues.email" 
          required 
          pattern="/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/"
          @blur="handleEmailBlur" />
          <span class="nop-required"></span>
          <span class="ui pointing red basic label" id="Email-error"></span>
        </div>
        <div class="field">
          <label for="BillingNewAddress_PhoneNumber">Telefonnummer:</label>
          <input type="text" id="BillingNewAddress_PhoneNumber" name="BillingNewAddress_PhoneNumber" @input="handleChange('phone')"  v-model="formValues.phone" required />
          <span class="ui pointing red basic label" id="PhoneNumber-error"></span>
        </div>
        </div>

     

      <div class="ui form edit-address" v-if="showShippingOptions && !excludeTax && !isSigning">

        <div class="field">
          <label for="BillingNewAddress_OrgNumber">Personnummer:</label>
          <input type="text" id="BillingNewAddress_OrgNumber" pattern="(19|20)\d{6}-\d{4}" placeholder="19840101-5574" name="BillingNewAddress_OrgNumber" required
            v-model="inputValueIdentity" @input="handleChangeIdentity" />
          <span class="nop-required"></span>
          <span class="ui pointing red basic label" id="OrgNumber-error"></span>
        </div>
        
        <div class="two fields">
          <div class="field">
            <label for="BillingNewAddress_FirstName">Förnamn:</label>
            <input type="text" id="BillingNewAddress_FirstName" name="BillingNewAddress_FirstName" @input="handleChange('firstName')"  v-model="formValues.firstName" required :disabled="disableFirstnameLastName" />
            <span class="nop-required"></span>
            <span class="ui pointing red basic label" id="FirstName-error"></span>
          </div>
          <div class="field">
            <label for="BillingNewAddress_LastName">Efternamn:</label>
            <input type="text" id="BillingNewAddress_LastName" name="BillingNewAddress_LastName" @input="handleChange('lastName')"  v-model="formValues.lastName" required :disabled="disableFirstnameLastName" />
            <span class="nop-required"></span>
            <span class="ui pointing red basic label" id="LastName-error"></span>
          </div>
        </div>
        <div class="field">
          <label for="BillingNewAddress_Address1">Adress:</label>
          <input type="text" id="BillingNewAddress_Address1" name="BillingNewAddress_Address1" @input="handleChange('address')"  v-model="formValues.address" required />
          <span class="ui pointing red basic label" id="Address1-error"></span>
        </div>
        <div class="two fields">
          <div class="field">
            <label for="BillingNewAddress_ZipPostalCode">Postkod:</label>
            <input type="text" id="BillingNewAddress_ZipPostalCode" name="BillingNewAddress_ZipPostalCode" @input="handleChange('zipPostalCode')" v-model="formValues.zipPostalCode" required />
            <span class="ui pointing red basic label" id="ZipPostalCode-error"></span>
          </div>

          <div class="field">
            <label for="BillingNewAddress_City">Postort:</label>
            <input type="text" id="BillingNewAddress_City" name="BillingNewAddress_City" @input="handleChange('city')" v-model="formValues.city" required />
            <span class="ui pointing red basic label" id="City-error"></span>
          </div>
        </div>
        <div class="field">
          <label for="BillingNewAddress_Email">E-post:</label>
          <input type="email" id="BillingNewAddress_Email" name="BillingNewAddress_Email" @input="handleChange('email')"
            v-model="formValues.email" 
            required 
            pattern="/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/"
            @blur="handleEmailBlur"
            :class="{ 'red-border': hasTouchedEmail && isEmailInvalid }" />
          <span class="nop-required"></span>
          <span class="ui pointing red basic label" id="Email-error"></span>
        </div>
        <div class="field">
          <label for="BillingNewAddress_PhoneNumber">Telefonnummer:</label>
          <input type="text" id="BillingNewAddress_PhoneNumber" name="BillingNewAddress_PhoneNumber" @input="handleChange('phone')" v-model="formValues.phone" required />
          <span class="ui pointing red basic label" id="PhoneNumber-error"></span>
        </div>
      </div>
     
      <div v-if="showShippingAddressForm && showShippingOptions && !isSigning" class="ui form edit-address">
        <br>
        <h3>Leveransadress</h3>
        <hr>
        <br>
        <div class="field">
          <label for="LevNewAddress_Address1">Adress:</label>
          <input type="text" id="LevNewAddress_Address1" name="LevNewAddress_Address1" @input="handleChange('address')"  v-model="formValuesLev.address" required />
          <span class="ui pointing red basic label" id="Address1-error"></span>
        </div>
        <div class="two fields">
          <div class="field">
            <label for="LevNewAddress_ZipPostalCode">Postkod:</label>
            <input type="text" id="LevNewAddress_ZipPostalCode" name="LevNewAddress_ZipPostalCode" @input="handleChange('zipPostalCode')" v-model="formValuesLev.zipPostalCode" required />
            <span class="ui pointing red basic label" id="ZipPostalCode-error"></span>
          </div>

          <div class="field">
            <label for="LevNewAddress_City">Postort:</label>
            <input type="text" id="LevNewAddress_City" name="LevNewAddress_City" @input="handleChange('city')" v-model="formValuesLev.city" required />
            <span class="ui pointing red basic label" id="City-error"></span>
          </div>
          </div>
        </div>
      
      <div class="book-button-container"><button type="button" class="ui button" id="book-button" v-if="!isSigning" :disabled="bookButtonIsDisabled" @click="startSigning">Boka</button></div>
      <div class="bank-id-pending" v-if="isSigning">
      <div class="bank-id-pending-img" style="padding: 8px">
        <img src="/Themes/RentorWebThemePublic/Content/images/BankId.png"/>
      </div>
        <h4>{{ bankIdMessage }}</h4>
        <i class="fa fa-spinner fa-spin-custom"></i>
      </div>
      <div style="text-align: center; margin-top: 8px">
        <button type="button" class="ui button small" v-if="isSigning" @click="cancelSigning">Avbryt</button>
      </div>
    </form>
  </div>
</template>

<script>
import CartService from "../services/cart.service";
import CartItemTableRow from "../components/CartItemTableRow.vue";
import CheckoutShippingFlyout from "../components/CheckoutShippingFlyout.vue";
import TaxSelectorFlyout from "../components/TaxSelectorFlyout.vue";
import Modal from "../components/Modal.vue";
import ShoppingCartCalculator from "../components/ShoppingCartCalculator.vue";
import ShareCart from "../components/ShareCart.vue";

import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

const cartService = new CartService();

export default {
  name: "CheckoutMainContainerFlyout",
  components: {
    CartItemTableRow,
    Datepicker,
    CheckoutShippingFlyout,
    TaxSelectorFlyout,
    Modal,
    ShoppingCartCalculator,
    ShareCart,
  },

  mounted() {

    window.addEventListener("cart-shown", () => {
      this.show = true;
    });

    window.addEventListener("cart-hidden", () => {
      this.show = false;
});

    window.addEventListener("added_to_cart", () => {
      
      cartService.getCart().then((response) => {
        const vm = this;
        if(vm.cart.length === 0) {
          $('.ui.sidebar').sidebar('toggle');
        }
        var hasRequieredProduct =
          response.data.cart.length - vm.cart.length > 1;
          var hasTwoRequieredProduct =
          response.data.cart.length - vm.cart.length > 2;
        if(hasTwoRequieredProduct) {
          vm.cart.unshift(response.data.cart[2]);
          vm.cart.unshift(response.data.cart[1]);
          vm.cart.unshift(response.data.cart[0]);
        }
        else if (hasRequieredProduct) {
          vm.cart.unshift(response.data.cart[1]);
          vm.cart.unshift(response.data.cart[0]);
        } else {
          vm.cart.unshift(response.data.cart[0]);
        }
        const lastCartItemWithDates = response.data.cart.slice().reverse().find(cartItem =>
          cartItem.cartItem.RentalStartDateUtc && cartItem.cartItem.RentalEndDateUtc
        );

        if (lastCartItemWithDates) {
          vm.startDate = new Date(lastCartItemWithDates.cartItem.RentalStartDateUtc);
          vm.endDate = new Date(lastCartItemWithDates.cartItem.RentalEndDateUtc);
          vm.endDate.setHours(12);
          vm.startDate.setHours(12);
          vm.setEndDates(this.endDate);
        }

        response.data.cart.forEach((item) => {
          
          this.setPriceProps(item);
          item.attributes = response.data.individualsByShoppingCartId[
            item.cartItem.Id
          ]?.sort((i) => i.indexOf(item.product.Sku) > -1);
          
        });

      });
    });

    // const vm = this;
    // window.addEventListener("add_order", function () {
    //   vm.hasClickedAddOrder = true;
    //   vm.setCart();
    // });
  },
  data() {
    return {
      hasTouchedEmail: false,
      cart: [],
      stockholmIsOpen: false,
      orderTotalsOrigin: {},
      showRentalDetails: false,
      showSaleDetails: false,
      startDate: new Date(),
      endDate: null,
      fromDate: new Date(),
      shippingCost: 0,
      selectedShippingMethod: "",
      fullshippingMethod: {},
      orderComment: "",
      isInStore: false,
      hasClickedAddOrder: false,
      excludeTax: true,
      showModal: false,
      shippingSelectionDisabled: false,
      showShippingOptions: false,
      showBillingForm: false,
      minEndDate: new Date(),
      days: 1,
        weeks: 1,
        months: 1,
        isWeekend: false,
      inputValueIdentity: "",
      inputValueIdentityPnrOrderer: "",
      isSigning: false,
      isSigned: false,
      signerName: null,
      signatureDate: null,
      signingIntervalId: null,
      bookButtonIsDisabled: true,
      showShippingAddressForm: false,
      formValues: {
        orgNumber: '',
        company: '',
        emailBilling: '',
        address: '',
        zipPostalCode: '',
        city: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      },
      formValuesLev: {
        address: '',
        zipPostalCode: '',
        city: ''
      },
      isFormValid: false,
      requiredFields: ['address', 'zipPostalCode', 'city', 'firstName', 'lastName', 'email', 'phone'],
      bankIdMessage: "Öppna BankId på din telefon och signera bokningen",
      show: false,
      disableFirstnameLastName: false
    };
  },
  computed: {
    
    isEmailInvalid() {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return this.hasTouchedEmail && !emailRegex.test(this.formValues.email);
    },

    totalPricePerDay: function(){
        if(!this.cart.length) return;
        var rentalObjects =  this.cart.filter(item => !!item.cartItem.RentalStartDateUtc);
        if(!rentalObjects.length) return;
        
        let days = this.days;
        if (this.isWeekend) {
          days = 2; // Subtract 2 from the total number of days
        }
        return Math.round(rentalObjects.map(item => item.product.Price * item.cartItem.Quantity).reduce((a, b) => {
          return a + b;
        }) * days * (this.excludeTax ? 1 : 1.25));
      },
      totalPricePerWeek: function(){
        if(!this.cart.length) return;
        var rentalObjects =  this.cart.filter(item => !!item.cartItem.RentalStartDateUtc);
        if(!rentalObjects.length) return;
        return Math.round(rentalObjects.filter(item => !!item.cartItem.RentalStartDateUtc).map(item => item.product.WeekPrice * item.cartItem.Quantity).reduce((a, b) => {
          return a + b;
        }) * this.weeks * (this.excludeTax ? 1 : 1.25));
      },
      totalPricePerMonth: function(){
          if(!this.cart.length) return;
          var rentalObjects =  this.cart.filter(item => !!item.cartItem.RentalStartDateUtc);
        if(!rentalObjects.length) return;
        return Math.round(rentalObjects.filter(item => !!item.cartItem.RentalStartDateUtc).map(item => item.product.MonthPrice * item.cartItem.Quantity).reduce((a, b) => {
          return a + b;
        }) * this.months * (this.excludeTax ? 1 : 1.25));
      },
    bestPrice:function(){
      {
        
        let lowest = this.totalPricePerDay;
        if(this.isWeekend) {
          return {totalPrice: lowest, period: period, text: "Baserat på helghyra"};
        }
        let period = "dygn";
        let text = "Baserat på "+this.days+" dygn";
        if (this.totalPricePerWeek < lowest) {
          lowest = this.totalPricePerWeek;
           period = "vecka";
           text = "Baserat på "+this.weeks+" veckor";
        }

        if (this.totalPricePerMonth < lowest) {
          lowest = this.totalPricePerMonth;
          text = "månad";
          text = "Baserat på "+this.months+" månader";
        }

        return {totalPrice: lowest, period: period, text: text};
      }
    },

    excludeTaxText: function () {
      return this.excludeTax ? "Moms" : "Varav moms";
    },
    externalShippingCost: function () {
      var cart;
      if (this.cart.length) cart = this.cart;
      var numberArray = cart.map(
        (item) => item.product.AdditionalShippingCharge
      );
      var additionalShippingCostSum = Math.max(...numberArray) * 2;
      if (additionalShippingCostSum == 0) return 198;

      var additionalShippingCostSumAnyIsOfferable = Math.min(...numberArray) * 2;

      if (additionalShippingCostSumAnyIsOfferable < 0) {
        return 0;
      }
      return additionalShippingCostSum

    },
    orderTotals: function () {
      if (this.cart.length > 0) {
        var rentalTotalPerDayExTaxes = 0;
        var rentalTotalMinDebIncTaxes = 0;
        var salesTotalExTaxes = 0;
        this.cart.forEach((item) => {
          if (item.cartItem.RentalStartDateUtc !== null) {
            rentalTotalPerDayExTaxes += this.mapActivePrice(item);
            rentalTotalMinDebIncTaxes += this.mapActivePrice(item, false) * 1.25;

            if (item.cartItem.RentalEndDateUtc !== null) {

              let daysBetween = (date_1, date_2) => {
                let difference = date_1.getTime() - date_2.getTime();
                let totalDays = Math.ceil(difference / (1000 * 3600 * 24));

                // Check if the first selected date is a Friday and the second date is a Monday
                if (date_1.getDay() === 5 && date_2.getDay() === 1) {
                  return totalDays - 2; // Subtract 2 from the total number of days
                }

                return totalDays;
              };
              rentalTotalPerDayExTaxes = rentalTotalPerDayExTaxes * daysBetween(new Date(item.cartItem.RentalEndDateUtc), new Date(item.cartItem.RentalStartDateUtc));

            }

          } else {
            salesTotalExTaxes += item.product.Price * item.cartItem.Quantity;
          }
        });
         


        var taxSale = Math.round(salesTotalExTaxes * 0.25);
        const totalValueToUse = !this.endDate ? rentalTotalMinDebIncTaxes : (this.bestPrice.totalPrice * (this.excludeTax ? 1.25 : 1));
        return {
          ...this.orderTotalsOrigin,
          excludeTax: this.excludeTax,
          rentalTotalPerDayExTaxes: Math.round(rentalTotalPerDayExTaxes),
          taxRentalPerDay: Math.round(rentalTotalPerDayExTaxes * 0.25),
          taxSale: taxSale,
          rentalTotalMinDebIncTaxes: Math.round(
            totalValueToUse +
            salesTotalExTaxes +
            taxSale +
            (this.shippingCost * 1.25)
          ),
          salesTotalExTaxes: Math.round(salesTotalExTaxes),
        };
      }
      return this.orderTotalsOrigin;
    },
    isAllRentalSamePeriod: {
      get: function () {
        var rentalCart = this.cart?.filter(
          (c) => c.cartItem.RentalStartDateUtc !== null
        );
        return rentalCart.every(
          (item) =>
            item.cartItem.LongTermRentPriceIndex.toString() ===
            rentalCart[0]?.cartItem.LongTermRentPriceIndex.toString()
        );
      },
      set: function (newValue) {
        // this.isAllRentalSamPeriod = newValue
      },
    },
    allLongTermPeriodReadValue: {
      get: function () {
        var rentalCart = this.cart?.filter(
          (c) => c.cartItem.RentalStartDateUtc !== null
        );
        return this.isAllRentalSamePeriod
          ? rentalCart[0]?.cartItem.LongTermRentPriceIndex.toString() === "-1"
            ? "dag"
            : rentalCart[0]?.cartItem.LongTermRentPriceIndex.toString() === "3"
              ? "vecka"
              : "månad"
          : "dag";
      },
      set: function (newValue) { },
    },
  },

  created() {
    this.initCart();
  },
  methods: {
    setStartDates(val) {
      if(this.endDate && val > this.endDate) {
        this.setEndDates(null);
      }
      this.minEndDate = val;
      this.startDate = val;
      this.cart.forEach((item) => {
        if (!item.cartItem.RentalStartDateUtc) return;

        item.cartItem.RentalStartDateUtc = val;
      });
      if(this.endDate) {
        this.dateChanged();
      }
    },
    setEndDates(val) {

      this.endDate = val;
      this.cart.forEach((item) => {
        if (!item.cartItem.RentalStartDateUtc) return;
        item.cartItem.RentalEndDateUtc = val;
        item.cartItem.RentalStartDateUtc = this.startDate;
      });
      this.dateChanged();
    },
    dateChanged() {
      if(!this.endDate) {
        this.isWeekend = false;
        return;
      }

        var difference = this.endDate.valueOf() - this.startDate.valueOf();
        this.days = Math.ceil(difference / (1000 * 3600 * 24));
        this.weeks = Math.ceil(difference / (1000 * 3600 * 24) / 7);
        this.months = Math.ceil(difference / (1000 * 3600 * 24) / 30);
        
        if (this.startDate.getDay() === 5 && this.endDate.getDay() === 1 && this.days < 5) {
           this.isWeekend = true; // Subtract 2 from the total number of days
        }else{
          this.isWeekend = false; // Subtract 2 from the total number of days

        }

      },
    


    taxIsChanged(excludeTax) {
    

      this.showShippingOptions = true;
      this.excludeTax = excludeTax;

      setTimeout(function () {
        $('input#BillingNewAddress_OrgNumber').val("");
        if (excludeTax) {
          // $('label[for="BillingNewAddress_OrgNumber"]').html("Organisationsnummer:");
          // $('label[for="BillingNewAddress_EmailBilling"]').show();
          // $('label[for="BillingNewAddress_Company"]').show();
          // $('input#BillingNewAddress_Company').show();
          // $('input#BillingNewAddress_EmailBilling').show();

          // $('input#BillingNewAddress_Company').val("");
          // $('input#BillingNewAddress_Company').closest('.inputs').show();
          // $('input#BillingNewAddress_Company').attr('required', 'required');
        }
        else {
          // $('label[for="BillingNewAddress_OrgNumber"]').html("Personnummer:");
          // $('label[for="BillingNewAddress_Company"]').hide();
          // $('input#BillingNewAddress_Company').hide();
          // $('input#BillingNewAddress_EmailBilling').hide();
          // $('label[for="BillingNewAddress_EmailBilling"]').hide();
          // $('input#BillingNewAddress_Company').val('Privat');
          // $('input#BillingNewAddress_Company').closest('.inputs').hide();
          // $('input#BillingNewAddress_Company').attr('required', null);
        }
      }, 10)
    },

    setCart() {
      cartService
        .setCart(this.cart, this.orderComment)
        .then(function (response) {
          if (response.data.success === 1) {
            if (typeof addOrderAdminWay === "function") {
              addOrderAdminWay();
            } else {
              Billing.save();
            }
          } else {
            alert(
              "Något gick fel, uppdatera sidan och försök igen eller ring till oss så löser vi problemet"
            );
          }
        });
    },
    initCart() {
      cartService.getCart().then((data) => {
        // this.taxIsChanged(data.data.excludeTax, true);
        this.isInStore = data.data.isInStore;
        this.orderTotalsOrigin = data.data.totals;
        this.selectedShippingMethod = data.data.selectedShippingOption.Name;
        this.showShippingAddressForm = !data.data.selectedShippingOption.IsPickupInStore;
        this.fullshippingMethod = data.data.selectedShippingOption;
        this.stockholmIsOpen = data.data.sthlmIsOpen;
        const lastCartItemWithDates = data.data.cart.slice().reverse().find(cartItem =>
          cartItem.cartItem.RentalStartDateUtc && cartItem.cartItem.RentalEndDateUtc
        );

        if (lastCartItemWithDates) {
          const startDate = new Date(lastCartItemWithDates.cartItem.RentalStartDateUtc);
          const endDate = new Date(lastCartItemWithDates.cartItem.RentalEndDateUtc);
          endDate.setHours(12);
          startDate.setHours(12);
          this.startDate = startDate;
          this.endDate =  endDate;
          this.setEndDates(this.endDate);
        }
        data.data.cart.forEach((item) => {
          
          this.setPriceProps(item);
          item.attributes = data.data.individualsByShoppingCartId[
            item.cartItem.Id
          ]?.sort((i) => i.indexOf(item.product.Sku) > -1);
          this.cart.push(item);
        });
        this.calculateAdditionalShipping(data.data.cart);
        let shippingCostCalc = data.data.selectedShippingOption.Name.indexOf("rakt") > -1
          ? this.externalShippingCost
          : data.data.selectedShippingOption.Rate;
        this.shippingCost = shippingCostCalc > -1 ? shippingCostCalc : 0;
        this.shippingSelectionDisabled = data.data.cart.some(function (c) {
          return !!c.cartItem.AttributesXml && (c.cartItem.AttributesXml.indexOf('Stockholm') > -1 || c.cartItem.AttributesXml.indexOf('Hisings Backa') > -1);
        });
      });
    },
    calculateAdditionalShipping(cart) {
      if (this.cart.length) cart = this.cart;
      var numberArray = cart.map(
        (item) => item.product.AdditionalShippingCharge
      );
      var additionalShippingCostSum = Math.max(...numberArray) * 2;
      var additionalShippingCostSumAnyIsOfferable = Math.min(...numberArray) * 2;

      if (additionalShippingCostSumAnyIsOfferable < 0) {
        this.externalShippingCost = additionalShippingCostSumAnyIsOfferable;
      }
      else {
        this.externalShippingCost = additionalShippingCostSum;

      }

    },
    onShippingChanged(newMethod) {
      
      this.showBillingForm = true;
      this.shippingCost = newMethod.cost;
      this.selectedShippingMethod = newMethod.textType;
      const newMethodShouldShowShippingAddress = newMethod.currentTarget.value?.indexOf('PickupInStore') < 0 || newMethod.currentTarget.dataset.value?.indexOf('PickupInStore') < 0 ;
      
      if(newMethodShouldShowShippingAddress){
        this.showShippingAddressForm = true;
      }
      else{
        this.showShippingAddressForm = false;
      }
      cartService.setShippingOptionSilent(newMethod.currentTarget);
    },
    removeFromCart(item) {
      this.cart = this.cart.filter(
        (c) =>
          c.cartItem.Id !== item.cartItem.Id &&
          c.cartItem.RequirerItemId !== item.cartItem.Id
      );
      cartService
        .setCart(this.cart, this.orderComment);
    },
    quantityChangeItem(item, increase) {
      this.cart
        .filter((c) => c.cartItem.RequirerItemId === item.cartItem.Id)
        .forEach((c) => {
          c.cartItem.Quantity = increase
            ? c.cartItem.Quantity + 1
            : c.cartItem.Quantity - 1;
        });
    },
    setPriceProps(item) {
      var totalPrice =
        item.product.Price *
        item.cartItem.Quantity *
        (this.excludeTax ? 1 : 1.25);
      var unitPrice = item.product.Price * (this.excludeTax ? 1 : 1.25);
      var price = {
        unitPrice: unitPrice,
        totalPrice: totalPrice,
      };
      item.price = price;
    },
    removeAllFromCart() {
      const vm = this;
      cartService.emptyCart().then(function (response) {
        if (response.data.success === 1) {
          vm.cart = [];
          vm.initCart();
        }
      });
    },
    handleChangeIdentityPnrForOreder() {
      const regex = /^(19|20)?(\d{8})-\d{4}$/;
      if (this.inputValueIdentityPnrOrderer.startsWith("19") || this.inputValueIdentityPnrOrderer.startsWith("20")) {
          this.inputValueIdentityPnrOrderer = this.inputValueIdentityPnrOrderer.replace(/^(\d{8})(\d{4})$/, '$1-$2');
        }
        if (regex.test(this.inputValueIdentityPnrOrderer)) {
          this.callApi(this.inputValueIdentityPnrOrderer, true);
        }
    },
    handleChangeIdentity() {
      // Check if the input matches a Swedish personal/organization number
      const regex = /^(19|20)?(\d{8})-\d{4}$/;
      const regexOrg = /^(55)?(\d{6})-\d{4}$/;
      // Add hyphen after the sixth digit
      if(!this.excludeTax) {

        if (this.inputValueIdentity.startsWith("19") || this.inputValueIdentity.startsWith("20")) {
          this.inputValueIdentity = this.inputValueIdentity.replace(/^(\d{8})(\d{4})$/, '$1-$2');
        }
        else if (this.inputValueIdentity.length === 10){
          let tmp = this.inputValueIdentity.slice(0, 2) >= 20 ? "19" : "20";
          this.inputValueIdentity =  tmp + this.inputValueIdentity;
          this.inputValueIdentity = this.inputValueIdentity.replace(/^(\d{8})(\d{4})$/, '$1-$2');
        }
        if (regex.test(this.inputValueIdentity)) {
          this.callApi(this.inputValueIdentity);
        }
      }
      else {
        if (this.inputValueIdentity.startsWith("55")) {
          this.inputValueIdentity = this.inputValueIdentity.replace(/^(\d{6})(\d{4})$/, '$1-$2');
        }
        if (regexOrg.test(this.inputValueIdentity)) {
          this.callApi(this.inputValueIdentity);
        }

      }
      
    },
  
    handleEmailBlur() {
      this.hasTouchedEmail = true;
    },

    handleChange(fieldName) {
      const regex = /^(19|20)?(\d{8})-\d{4}$/;
      const regexOrg = /^(55)?(\d{6})-\d{4}$/;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!this.showShippingAddressForm) {
        let missingFields = this.requiredFields.filter(field => !this.formValues[field]);

        if (
          missingFields.length === 0 &&
          this.inputValueIdentity.length > 0 &&
          (this.excludeTax ? regexOrg.test(this.inputValueIdentity) || regex.test(this.inputValueIdentity) : regex.test(this.inputValueIdentity)) &&
          emailRegex.test(this.formValues.email) // Check if email is valid
        ) {
          this.bookButtonIsDisabled = false;
        } else {
          this.bookButtonIsDisabled = true;
        }
      } else {
        let missingFields = this.requiredFields.filter(field => !this.formValues[field]);

        if (
          missingFields.length === 0 &&
          this.inputValueIdentity.length > 0 &&
          this.formValuesLev.city.length &&
          this.formValuesLev.zipPostalCode.length &&
          this.formValuesLev.address.length &&
          (this.excludeTax ? regexOrg.test(this.inputValueIdentity) || regex.test(this.inputValueIdentity) : regex.test(this.inputValueIdentity)) &&
          emailRegex.test(this.formValues.email) // Check if email is valid
        ) {
          this.bookButtonIsDisabled = false;
        } else {
          this.bookButtonIsDisabled = true;
        }
      }
    },

    callApi(legalId, isForOrderer) {
      legalId = legalId.replace('-', '');
      const vm = this;
      this.enableFirsnameLastName = true; 
      $.ajax({
        url: "/Valitive/GetSePerson",
        method: "GET",
        data: { legalId },
        dataType: "json",
        contentType: "application/json",
        success: function (response) {
          let isOrg = response._type === "SE_ORG";
        

          if (!isOrg) {
            const officalAddress = response.Addresses.filter(a => a.Kind === 'OFFICIAL');
          const address = officalAddress.length ? officalAddress[0]: null;
            // set the input value of förnamn
            vm.formValues.firstName = response.Name?.GivenName ?? "";
            if (!response.Name?.GivenName?.length) {
              document.getElementById('BillingNewAddress_FirstName').focus();
              
            }
            else{
              vm.disableFirstnameLastName = true;
             
            }
            // set the input value of efternamn
            vm.formValues.lastName = response.Name?.LastName ?? "";
            if (!response.Name.LastName?.length) {
              document.getElementById('BillingNewAddress_LastName').focus();
            }
            // set the input value of telefonnummer (assuming the response object has a 'Phones' array and the first item has a 'Number' property)
            // document.getElementById('BillingNewAddress_PhoneNumber').value = response.Phones[0] ? response.Phones[0] : "";
            if (!response.Phones[0]?.length) {
              document.getElementById('BillingNewAddress_PhoneNumber').focus();
            }
        

            document.getElementById('BillingNewAddress_Email').focus();
            if(!isForOrderer && address) {
              // set the input value of adress
              vm.formValues.address = address?.Street + ' ' + address?.Number;
              
              // set the input value of postkod
              vm.formValues.zipPostalCode = address?.Zip;
              
              // set the input value of postort
              vm.formValues.city = address?.City;

              vm.formValuesLev.address = address?.Street + ' ' + address?.Number;
              vm.formValuesLev.zipPostalCode = address?.Zip;
              vm.formValuesLev.city = address?.City;
            }
          }
          else {

            const officalAddress = response.addresses.filter(a => a.Kind === 'VISIT');
          const address = officalAddress.length ? officalAddress[0]: null;
            // set the input value of adress
            if(address) {

              vm.formValues.address = address?.Street + ' ' + address?.Number;
              vm.formValues.company = response.orgName.name;
              
              // set the input value of postkod
              vm.formValues.zipPostalCode = address?.Zip;
              
              // set the input value of postort
              vm.formValues.city = address?.City;
              
              vm.formValuesLev.address = address?.Street + ' ' + address?.Number;
              vm.formValuesLev.zipPostalCode = address?.Zip;
              vm.formValuesLev.city = address?.City;
            }
          }
          vm.handleChange();
        },
        error: function (xhr, status, error) {
          console.error(xhr.responseText);
        }
      });
    },

    cancelSigning(){
      const vm = this;
          $.get('/Order/CancelSignCallBack', response => {
            vm.isSigning = !response.isCanceled;
        });
    },
    startSigning() {
      // this.book();
      // return;
      // Show a loading indicator while the signing process is in progress.
      this.isSigning = true;
      const pnrThatSigns = (this.excludeTax ? this.inputValueIdentityPnrOrderer : this.inputValueIdentity).replace('-', ''); 
      // Make a request to the server to initiate the signing process.
      $.ajax({
        url: '/Order/InitiateBankIdSigning?pnr=' + pnrThatSigns,
        type: 'GET',
        contentType: 'application/json',
        success: response => {
          // Start polling the server for the signing status.
          this.checkSigningStatus();
        },
        error: error => {
          // Handle any errors that occur during the signing process.
          console.error(error);
        }
      });

      const phoneNumber = this.formValues.phone;
      const email = this.formValues.email;
       // Make a request to your custom endpoint
      $.ajax({
        url: '/Order/StartMvPing',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          Pnr: pnrThatSigns,
          Phone: phoneNumber,
          Email: email
        }),
        success: response => {
          // Handle the response here
       
        },
        error: error => {
          // Handle any errors that occur during the request.
          console.error(error);
        }
      });
    },
    checkSigningStatus() {
        // Set a variable to track the number of attempts
        let attempts = 0;

        // Define the maximum number of attempts
        const maxAttemptsCount = 60; // 60 attempts = 2 minutes (with 2-second delay)
        const vm = this;
        // Create a recursive function for polling
        function poll() {
            $.get('/Order/CheckSigningStatus', response => {
                if (response.isSigned) {
                    // The document has been signed.
                    vm.isSigned = true;
                    vm.bankIdMessage = "Skickar bokning";
                    vm.book();
                } else if (response.isCanceled) {
                    // The signing process has been canceled.
                    vm.isSigning = false;
                } else {
                    // The signing process is still pending
                    // Continue polling if the maximum number of attempts is not reached
                    if (attempts < maxAttemptsCount) {
                        attempts++;
                        setTimeout(poll, 2000); // Poll again after 2 seconds
                    } else {
                        $.get('/Order/CancelSignCallBack', response => {
                            vm.isSigning = !response.isCanceled;
                        });
                    }
                }
            })
                .fail(error => {
                    // Handle any errors that occur
                    // Optionally, you can retry or display an error message for the user
                });
        }

        // Start the polling when the page loads
        poll();
    },
    book() {
      const vm = this;
      vm.formValues.orgNumber = this.inputValueIdentity;
      cartService
        .setCart(this.cart, this.orderComment).then(function () {

          const formData = {
            FormValues: vm.formValues,
            FormValuesLev: vm.formValuesLev,
            ShippingOption: vm.selectedShippingMethod,
            OrderComment: vm.orderComment,
            EndDate: vm.endDate,
            StartDate: vm.startDate
          };

          $.ajax({
            url: "/Checkout/OpcSaveOrderAll",
            method: "POST",
            dataType: "json",
            data: JSON.stringify(formData),
            contentType: 'application/json',
            success: function (result) {
             
              if (result.success) {
                this.isSigning = false;
                window.location = "checkout/completed/" + '?orderId=' + result.orderId;
        }
            },
            error: function (xhr, status, error) {
              // Handle the error response
              console.log(error);
            }
          })
});
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-root {
  margin-top: 50px;
}

.taxRoot {
  margin-top: 10px;
}

.isMobile.v-root {
  margin-top: 0;
}

.ui.table tr.summary-details {
  display: none !important;
}

.ui.table tr.summary-details.show {
  display: table-row !important;
}

.edit-address {
  max-width: 500px!important;
    margin: 20px auto;
    width: 100%;
}

.ui.table .show-more-row td {
  cursor: pointer;
  border-top: none;
  text-align: center;
  padding: 4px 0;
}

.ui.table tbody+tbody .summary-details tr:first-child td {
  border-top: none;
}

.flex-just-center>div:first-of-type {
  margin-right: 7px;
}

.isMobile .flex-just-center>div:first-of-type {
  margin-right: 0;
}

.isInStore .summary-container {
  margin-top: calc(-1em - 25px);
}

.summary-container {
  flex: 0 0 340px;

}

.isMobile .summary-container {
  margin-top: 20px;
}

.product-table {
  align-self: baseline;
  margin-top: 0;
}

.isInStore .margin-summary-desktop {
  margin-top: -1em;
}

tr.empty-cart {
  padding: 2px 0 !important;
}

.one-row-force {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.isMobile .flex-just-center {
  flex-wrap: wrap;
}

.summary-details>td {
  box-shadow: inset 0px -6px 6px 0px #70707085;
}

.isInStore .if-public {
  display: none;
  width: 0px;
  height: 0px;
  padding: 0px;
}

.isInStore .if-public * {
  display: none;
  width: 0px;
  height: 0px;
  padding: 0px;
}

.one-row-force,
.right.aligned {
  font-size: 1.05em;
  color: #000;
}

.ui.celled.table input[type="button"] {
  padding: 10px;
  text-align: center;
  font-size: 13px;
  border-radius: 4px;
  text-transform: none;
}

.date-container {
  max-width: 500px;
  margin: auto;
}

.fa-spin-custom {
        animation: fa-spin 2s infinite linear;
      }

      @keyframes fa-spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
      }
      .bank-id-pending-img {
        max-width: 160px;
        margin:0 auto 10px;
      }
      .bank-id-pending {
        max-width: 280px;
        margin:auto;
        text-align: center;
      }
      .bank-id-pending i{
        font-size: 2em;
        margin-top: 10px
      }
      .bank-id-pending-img img {
        width: 100%

      }
      .book-button-container {
        margin: auto;
    width: 280px;
    min-width: 90%;
    max-width: 300px;
      }
      .book-button-container button{
        background-color: #03564A;
        color: #fff;
        width: 100%;
        margin: auto;
      }
      .book-button-container button:hover{
        background-color: #03564A!important;
        opacity: .7;
      }
      .red-border {
  border: 1px solid red!important;
}
</style>
