<template>

    <div class="taxRoot">
    <h2>Jag lägger ordern som</h2>

<div class="selectPeriodsDifferentRentSummary">
  <div>
    <label for="taxdisplaytypeexl" :class="{'active' : excludeTaxData}"> 
      <input type="radio" id="taxdisplaytypeexl" name="taxdisplaytype" value="10" @change="taxChanged(true)"> Företag <div>Exkl moms</div></label>
  </div>
  <div>
      <label for="taxdisplaytypeink" :class="{'active' : excludeTaxData === false}"> 
      <input type="radio" id="taxdisplaytypeink" name="taxdisplaytype" value="0" @change="taxChanged(false)"> Privat <div>Ink moms</div> </label>
    </div>
  </div>
  
  </div>
</template>

<script>

export default {
  emits: ['taxchangeflyout'],
  name: 'TaxSelectorFlyout',
  props:{
      excludeTax: Boolean,
  },
  mounted(){
  },
    data() {
      return {

        excludeTaxData: null
      }
    },
    
    methods: {
      
      taxChanged(excludeTax) {
      this.excludeTaxData = excludeTax;
     
      this.$emit('taxchangeflyout', excludeTax);
      }      
    }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.taxRoot {
  padding: 10px 0;
  border-top: 1px solid #03564a;

  margin-bottom: 10px;
}
label div {
  font-size: 11px;
  line-height: 5px;
}
.isMobile .taxRoot {
  right: 0;
  margin-top: 7px;
}
.selectPeriodsDifferentRentSummary > div {
  flex: 1 0;
}
.selectPeriodsDifferentRentSummary {
  margin: auto;
  justify-content: center;
}
h2{
  text-align: center;
  margin-bottom: 10px
}
</style>
