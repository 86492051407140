import axios from 'axios'
export default class CartService {

    getCart() {
        return axios.get("/Checkout/GetCart");
    }
    addToCart(cart){
        cart.push(cart[0]);
    }
    emptyCart() {
        return axios.get("/shoppingcart/ClearShoppingCart", { params: { shoppingCartTypeId: 1 } });
    }
    setShippingOptionSilent(currentTarget){
        $('.shipping-method li').removeClass('active');
        $(currentTarget.parentElement).closest('li').addClass('active');
      
        if (currentTarget.value?.indexOf('PickupInStore') < 0 || currentTarget.dataset.value?.indexOf('PickupInStore') < 0 ) {
            $('#checkout-step-shipping').removeClass('inactive');
        }
        else {
            $('#checkout-step-shipping').addClass('inactive');
        }
        ShippingMethod.saveSilent();
        
    }
    setCart(cart, orderComment) {
        var cartItems = cart.map(c => c.cartItem);
        cartItems.forEach(cartItem => {
            cartItem.LongTermRentPriceIndex = parseInt(cartItem.LongTermRentPriceIndex);
        });
        var formAndCart = {cartItems, orderComment};
        return axios.post("/Checkout/SaveCartCurrentCustomer", formAndCart);     
    }
    createAndGetShareCartLink(cart) {
        var cartItems = cart.map(function(item) {return item.cartItem});
        return axios.post("/shoppingcart/CreateLinkableCart", cartItems);
    }

  }