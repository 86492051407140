<template>

      <tr :class="{'requiered-product' : item.cartItem.RequirerItemId}" v-if="!isMobile()">
        <td class="center aligned image"><a :href="item.link"><img v-bind:src="item.image.ImageUrl" alt="{{item.image.AlternateText}}"></a></td>
        <td><a :href="item.link">{{item.product.Name}}</a>
        <br><small v-for="(attribute) in item.attributes"
        :key="attribute">{{attribute}}<br></small>
          <span v-if="item.cartItem.RentalEndDateUtc != null && typeof item.cartItem.RentalStartDateUtc === 'string'">{{item.cartItem.RentalStartDateUtc.split('T')[0]}} - {{item.cartItem.RentalEndDateUtc.split('T')[0]}}</span>
          <span v-else-if="item.cartItem.RentalEndDateUtc != null && item.cartItem.RentalStartDateUtc instanceof Date">{{item.cartItem.RentalStartDateUtc.toISOString().slice(0, 10)}} - {{item.cartItem.RentalEndDateUtc.toISOString().slice(0, 10)}}</span>
        </td>
        <td v-bind:data-sort-value="item.product.Sku">{{item.product.Sku}}</td>
        <td>
          <div class="selectPeriodsDifferentRentSummary" v-if="item.cartItem.RentalStartDateUtc !== null && item.cartItem.RentalEndDateUtc === null">
                <div v-if="item.product.Price !== item.product.MonthPrice || item.product.MonthPrice === 0">
                    <label :class="{'active':item.cartItem.LongTermRentPriceIndex.toString() === '-1'}">
                        <input type="radio" value="-1" v-model="item.cartItem.LongTermRentPriceIndex">Dag</label>
                </div>
                <div v-if="item.product.Price !== item.product.MonthPrice || item.product.MonthPrice === 0">
                    <label :class="{'active':item.cartItem.LongTermRentPriceIndex.toString() === '3'}">
                        <input type="radio" value="3" v-model="item.cartItem.LongTermRentPriceIndex">Vecka</label>
                </div>
                <div>
                <label :class="{'active':item.cartItem.LongTermRentPriceIndex.toString() === '2'}">
                    <input type="radio" value="2" v-model="item.cartItem.LongTermRentPriceIndex">Månad</label>
                </div>
            </div>
        </td>
        <td class="one-row-force" v-bind:data-sort-value="unitPrice">{{Math.round(unitPrice * (excludeTax ? 1 : 1.25))}} kr</td>
        <td class="one-row-force" v-bind:data-sort-value="subTotalPrice">{{Math.round(subTotalPrice * (excludeTax ? 1 : 1.25))}} kr</td>
        <td v-bind:data-sort-value="item.cartItem.Quantity">
            <vue-number-input @update:model-value="onQuantityChange" v-model="item.cartItem.Quantity" :value="item.cartItem.Quantity" :min="0" :max="99" size="small" :inputtable="false" :center="true" :attrs="{style: 'width: 6rem'}" :name="'itemquantity'+item.cartItem.Id" inline :controls="!item.cartItem.RequirerItemId"></vue-number-input>
        </td>
      </tr>
     <tr :class="{'requiered-product' : item.cartItem.RequirerItemId}" v-else>
        <td class="image">
          <div class="flex-items-start">
            <img v-bind:src="item.image.ImageUrl" alt="{{item.image.AlternateText}}">
              <a :href="item.link">
            <div class="flex-column" style="padding-top: 2px">
              <strong>{{item.product.Name}}</strong>
              <span>{{item.product.Sku}}</span>
              <span v-if="item.cartItem.RentalEndDate != null">{{item.cartItem.RentalStartDate}} {{item.cartItem.RentalEndDate}}</span>
            </div>
              </a>
     
          </div>

       
          <div class="flex-just-between flex-items-center">
            <div>
            <vue-number-input @update:model-value="onQuantityChange" v-model="item.cartItem.Quantity" :min="0" :max="99" size="small" :inputtable="false" :center="true" :attrs="{style: 'width: 6rem'}" inline :controls="!item.cartItem.RequirerItemId"></vue-number-input>
            </div>
               <div v-if="item.cartItem.RentalStartDateUtc !== null && item.cartItem.RentalEndDateUtc === null" style="margin-left: 3px;">
                 <form class='ui form small'>

            <select v-model="item.cartItem.LongTermRentPriceIndex" class="selectPeriodsDifferentRentSummary ui normal dropdown">
                <option value="-1">
                    Dag
                </option>
                <option value="3">
                    Vecka
                </option>
                <option value="2">
                    Månad
                </option>
            </select>               
                 </form>
          </div>
          <div>{{Math.round(unitPrice * (excludeTax ? 1 : 1.25))}} kr / st</div>
          <div>{{Math.round(subTotalPrice * (excludeTax ? 1 : 1.25))}} kr</div>
          </div>
          </td>
        
      </tr>
</template>

<script>

import VueNumberInput from '@chenfengyuan/vue-number-input';

export default {
   
  name: 'CartItemTableRow',
   component: {
        VueNumberInput
    },
  props:{
      item: Object,
      removeItem: Function,
      quantityChange: Function,
      excludeTax: Boolean
  },
 
    data() {
      return {
      }
    },
    computed: {
        
          subTotalPrice: {
            get() {
              return this.unitPrice * this.item.cartItem.Quantity;
          },
          set(newValue){

          }
          },
          unitPrice: {
            get() {
              return this.mapActivePrice(this.item, true);
          },
          set(newValue){

          }
          }
           
      
    },
    
    created(){

    },
    
    methods: {
      onQuantityChange(newValue) {
        if(newValue === this.item.cartItem.Quantity) return;
          if(newValue === 0) {
              this.removeItem(this.item);
          }
          else {
            this.quantityChange(this.item, newValue > this.item.cartItem.Quantity);
          }
      },
      mapActivePrice(item, perUnit) {
        var returnPrice = 0;
        //day
        if(parseInt(item.cartItem.LongTermRentPriceIndex) === -1) {
           returnPrice = item.product.Price;
        }
        //week
        else if(parseInt(item.cartItem.LongTermRentPriceIndex) === 3) {
          returnPrice = item.product.WeekPrice;
        }
         //week
        else if(parseInt(item.cartItem.LongTermRentPriceIndex) === 2) {
          returnPrice = item.product.MonthPrice;
        }
     
        return returnPrice * (perUnit ? 1 : item.cartItem.Quantity);
      }
    }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.ui.compact.table tr.requiered-product td {
    opacity: 0.9 ;
    padding: 0 8px;
    border-top: 1px dashed rgba(34,36,38,.1);
}
.ui.compact.table tr td img {
  width: 60px;
}
.ui.compact.table tr td.image {
  padding: 0;
}
.ui.compact.table tr.requiered-product td img {
  width: 40px;
  padding-top: 5px
}
.requiered-product td.image {
  position: relative;
}
.requiered-product td.image img {
  display: none;
}
.requiered-product td.image::before {
    content: "\f149";
    position: absolute;
    font-size: 16px;
    left: 50%;
    font-family: FontAwesome;
    transform: translate(-50%,-50%) scaleX(-1) rotate(90deg);
}
.one-row-force {
   white-space: nowrap; overflow: hidden; text-overflow:ellipsis; 
}
.ui.table:not(.unstackable) td:first-child {
  font-weight: normal;
}



@media only screen and (max-width: 767px) {

}

</style>
