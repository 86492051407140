<template>
    <div class="calculator-root">   
      <div>
   
<div style="width: fit-content">
  <h3>Välj planerade datum för er hyra</h3>
      <div>

      <Datepicker v-model="date"
      twoCalendars
      range
      locale="sv-SE"
      cancelText="Avbryt"
      selectText="Välj"
      autoApply
      noToday
      :format="'yyyy-MM-dd'"
      :enableTimePicker="false"
      :clearable="false"
      @update:modelValue="dateChanged"
                                     />
      </div>
      
</div>
      </div>
    <div class="selected-date">
      <div>

      <h2>Prisuppgifter när ni hyr mellan:</h2>
      <h3>
        {{date[0].toLocaleDateString('sv-SE', dateOptions)}} - {{date[1].toLocaleDateString('sv-SE', dateOptions)}}
      </h3>
      </div>
        <TaxSelector v-on:taxchange="taxOnCalculatorIsChanged" :excludeTax="excludeTax"  v-if="cart.length"></TaxSelector>
       </div>

  <div class="result-calculator">
    <div class="day-container">
      <table class="ui table" :class="{'lowest' : totalPricePerDay < totalPricePerWeek && totalPricePerDay < totalPricePerMonth, 'small' : isMobile()}">
        <thead>
          <tr>
          <th colspan="2">Om ni väljer per DAG<br><small>Baserat på {{days}} dagar</small></th>
          </tr>
        </thead>
        <tbody>
          <tr>
              <td>Totalt pris hyresobjekt</td>
            <td>{{totalPricePerDay}} kr</td>
          </tr>
            <tr>
              <td>Pris per dag</td>
            <td>{{Math.round(totalPricePerDay / days)}} kr</td>
          </tr>
           <tr>
              <td>Totaltpris försäljningobjekt</td>
            <td>{{totalPriceSales}} kr</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="week-container">
<table class="ui table" :class="{'lowest' : totalPricePerWeek < totalPricePerDay && totalPricePerWeek < totalPricePerMonth, 'small' : isMobile()}">
        <thead>
          <tr>
          <th colspan="2">Om ni väljer per VECKA<br><small>Baserat på {{weeks}} veckor</small></th>
          </tr>
          
        </thead>
        <tbody>
          <tr>
              <td>Totalt pris hyresobjekt</td>
            <td>{{totalPricePerWeek}} kr</td>
          </tr>
          <tr>
              <td>Pris per dag</td>
            <td>{{Math.round(totalPricePerWeek / days)}} kr</td>
          </tr>
           <tr>
              <td>Totaltpris försäljningobjekt</td>
            <td>{{totalPriceSales}} kr</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="month-container">
<table class="ui table" :class="{'lowest' : totalPricePerMonth < totalPricePerWeek && totalPricePerMonth < totalPricePerDay, 'small' : isMobile()}">
        <thead>
          <tr>
          <th colspan="2">Om ni väljer per MÅNAD<br><small>Baserat på {{months}} månader</small></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Totalt pris hyresobjekt</td>
            <td>{{totalPricePerMonth}} kr</td>
          </tr>
          <tr>
              <td>Pris per dag</td>
            <td>{{Math.round(totalPricePerMonth / days)}} kr</td>
          </tr>
          <tr>
              <td>Totaltpris försäljningobjekt</td>
            <td>{{totalPriceSales}} kr</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
<table class="ui sortable compact celled table small tablet stackable product-table">
  <thead>
    <tr v-if="!isMobile()">
    <th class="no-sort"></th>
    <th>Produkt</th>
    <th>Artikelnr</th>
    <th>Period</th>
    <th>Pris</th>
    <th>Summa</th>
    <th>Antal produkter</th>
    </tr>
  </thead>
  <tbody>
      <CartItemTableRow
      v-for="(item) in cart"
        :key="item.cartItem.Id"
         :item="item" 
         :removeItem="removeFromCart"
        :quantityChange="quantityChangeItem"
        :excludeTax="excludeTax" />
      <tr class="empty-cart">
        <td colspan="5">
          <span style="color: #f18a65;">Du skickar eller lämnar tillbaka sakerna när du är klar. Så du behöver inte välja slutdatum för din hyra</span>
        </td>
        <td colspan="2" class="right aligned">
          <input type="button" style="background-color: white; color: #03564A;" name="emptyCart" value="Töm varukorgen" class="button-rentor-second remove-all-button update-cart-button" @click="removeAllFromCart" onclick="removeAllInCart()">
        </td>
      </tr>
      </tbody>
    </table>

    </div>
</template>

<script>
import CartItemTableRow from '../components/CartItemTableRow.vue'
import TaxSelector from '../components/TaxSelector.vue'
import Datepicker from "vue3-date-time-picker";

export default {
   components: {
      CartItemTableRow,
      TaxSelector,
      Datepicker
   },
  name: 'ShoppingCartCalculator',
  props:{
      inCart: Array,
      inExcludeTax: Boolean,
      TaxSelector,
      Datepicker
  },
  mounted(){
    $('.master-wrapper-content').css('z-index', 100);
  },
  unmounted(){
    $('.master-wrapper-content').css('z-index', 'unset');

  },
    data() {
       const startDate = new Date();
        const endDate = new Date(new Date().setDate(startDate.getDate() + 1));
      return {
        cart:  JSON.parse(JSON.stringify(this.inCart)),
        excludeTax: this.inExcludeTax,
        date: [startDate, endDate],
        days: 1,
        weeks: 1,
        months: 1,
        dateOptions: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      }
    },
    computed:{
      totalPricePerDay: function(){
        if(!this.cart.length) return;
        var rentalObjects =  this.cart.filter(item => !!item.cartItem.RentalStartDateUtc);
        if(!rentalObjects.length) return;
        return Math.round(rentalObjects.map(item => item.product.Price * item.cartItem.Quantity).reduce((a, b) => {
          return a + b;
        }) * this.days * (this.excludeTax ? 1 : 1.25));
      },
      totalPricePerWeek: function(){
        if(!this.cart.length) return;
        var rentalObjects =  this.cart.filter(item => !!item.cartItem.RentalStartDateUtc);
        if(!rentalObjects.length) return;
        return Math.round(rentalObjects.filter(item => !!item.cartItem.RentalStartDateUtc).map(item => item.product.WeekPrice * item.cartItem.Quantity).reduce((a, b) => {
          return a + b;
        }) * this.weeks * (this.excludeTax ? 1 : 1.25));
      },
      totalPricePerMonth: function(){
          if(!this.cart.length) return;
          var rentalObjects =  this.cart.filter(item => !!item.cartItem.RentalStartDateUtc);
        if(!rentalObjects.length) return;
        return Math.round(rentalObjects.filter(item => !!item.cartItem.RentalStartDateUtc).map(item => item.product.MonthPrice * item.cartItem.Quantity).reduce((a, b) => {
          return a + b;
        }) * this.months * (this.excludeTax ? 1 : 1.25));
      },
      totalPriceSales: function(){
           if(!this.cart.length) return;
          var saleObjects =  this.cart.filter(item => !item.cartItem.RentalStartDateUtc);
        if(!saleObjects.length) return;
         return Math.round(this.cart.filter(item => !item.cartItem.RentalStartDateUtc).map(item => item.product.Price * item.cartItem.Quantity).reduce((a, b) => {
          return a + b;
        }) * (this.excludeTax ? 1 : 1.25));
      }
    },
    methods: {     
            removeFromCart(item){
        this.cart = this.cart.filter(c => c.cartItem.Id !== item.cartItem.Id && c.cartItem.RequirerItemId !== item.cartItem.Id);
      },
      quantityChangeItem(item, increase) {
        this.cart.filter(c => c.cartItem.RequirerItemId === item.cartItem.Id).forEach(c => {
          c.cartItem.Quantity = increase ? c.cartItem.Quantity + 1 : c.cartItem.Quantity - 1;
        })
      },
      dateChanged(newVal) {
        var difference = newVal[1].valueOf() - newVal[0].valueOf();
        this.days = Math.ceil(difference / (1000 * 3600 * 24));
        this.weeks = Math.ceil(difference / (1000 * 3600 * 24) / 7);
        this.months = Math.ceil(difference / (1000 * 3600 * 24) / 30);
      },
      taxOnCalculatorIsChanged(excludeTax){
        this.excludeTax = excludeTax;

      }
    }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}


.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}
.product-table {
  margin-top: 50px;
}
.isMobile .result-calculator > * {
  width: 49%;
}

.table.lowest {
  border: 2px solid #0caa26;
}
.result-calculator {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  flex-wrap: wrap;
}

.selected-date {
  margin: 20px 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
