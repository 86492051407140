<template>
<div class="share-cart-root">
  <div>

  <button type="button" class="button-rentor-second" @click="shareCart">Dela varukorgen</button>
  <div class="copied-toaster" v-if="showToaster">
    Kopierad
  </div>
  <div v-if="shareLink" class="share-link flex-items-center">
    <i v-clipboard:copy="shareLink" v-clipboard:success="onCopy" class="fa fa-clipboard"></i>
    <a class="link">{{shareLink}}</a>

  </div>
  </div>

</div>
</template>

<script>
import CartService from '../services/cart.service';
const cartService = new CartService();

export default{
  name: 'ShareCart',
  props:{
    cart: Object
  },
  mounted(){
  },
  data() {
    return {
      shareLink: null,
      showToaster: false
    }
  },
    
  methods: {
    shareCart(){
      cartService.createAndGetShareCartLink(this.cart).then(data => {
        this.shareLink = data.data;
      })
    },
    onCopy() {
      const vm = this;
      vm.showToaster = true;
     setTimeout(function(){
       vm.showToaster = false}, 2000)
    }
  }      
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.share-cart-root {

}
.share-link {
  position: relative;
  width: fit-content;
  max-width: 318px;
  border: 1px solid;
  margin-top: 5px;
      padding: 0 5px;
      border-radius: 4px;
}
.share-link i {
  font-size: 20px;
  padding-right: 10px;

  cursor: pointer;
}
.copied-toaster {
  text-align: center;
  padding: 3px;
  font-size: 14px;
  
}
.share-link a {
  white-space: nowrap;
    overflow: auto;
    padding: 3px 3px 3px 10px;
    border-left: 1px solid;
}
.share-link a::-webkit-scrollbar {
    display: none;
}
/* .share-link i:hover::before{

content: "Klicka för att kopiera länken";

background-color: #fff;
color: #000;
top: -100%;

} */


</style>
