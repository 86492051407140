<template id="modal-template">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header flex-just-between">
            <slot name="header">
              default header
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
             
              <button class="modal-default-button" type="button" @click="$emit('close')">
                Stäng
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
   
  name: 'Modal',
  props:{
      showModal: Boolean
  },
  mounted(){

  },
    data() {
      return {
      }
    },
    
    methods: {      
    }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
      overflow-y: auto;
    z-index: 1100;
    max-height: 90vh;
    width: 95%;
    margin: 0px auto;
  max-width: 900px;
  padding: 0px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  
}
.modal-footer {
  text-align: right;
}
.modal-default-button {
  padding: 5px;
  margin: 1px;
}
.modal-header, .modal-footer {
padding: 20px;
    margin: 0 -30px;
    background-color: rgba(0 , 0,0,0.03); 
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}


</style>
