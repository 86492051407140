<template>
<div class="taxRoot">

<div class="selectPeriodsDifferentRentSummary">
  <div>
    <label for="taxdisplaytypeexl" :class="{'active' : excludeTaxData}"> 
      <input type="radio" id="taxdisplaytypeexl" name="taxdisplaytype" value="10" @change="taxChanged(true)"> Företag <div>Exkl moms</div></label>
  </div>
  <div>
      <label for="taxdisplaytypeink" :class="{'active' : !excludeTaxData}"> 
      <input type="radio" id="taxdisplaytypeink" name="taxdisplaytype" value="0" @change="taxChanged(false)"> Privat <div>Ink moms</div> </label>
  </div>
</div>

</div>
</template>

<script>

export default {
  emits: ['taxchange'],
  name: 'TaxSelector',
  props:{
      excludeTax: Boolean,
  },
  mounted(){
  },
    data() {
      return {
        excludeTaxData: this.excludeTax
      }
    },
    
    methods: {
      
      taxChanged(excludeTax) {
      this.excludeTaxData = excludeTax;
      this.$emit('taxchange', excludeTax);
      }      
    }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

label div {
  font-size: 11px;
  line-height: 5px;
}
.isMobile .taxRoot {
  right: 0;
  margin-top: 7px;
}
</style>
