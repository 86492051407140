<template>
  <div :class="{ isInStore: isInStore, isMobile: isMobile() }" class="v-root">
    <form id="shopping-cart-form" enctype="multipart/form-data">
      <input name="__RequestVerificationToken" type="hidden" />
      <div class="flex-just-center">
        <div>
          <TaxSelector
            v-on:taxchange="taxIsChanged"
            :excludeTax="excludeTax"
            v-if="cart.length && !showModal"
          ></TaxSelector>

          <!-- use the modal component, pass in the prop -->
          <transition name="modal">
            <Modal v-if="showModal" @close="showModal = false">
              <!--
      you can use custom content here to overwrite
      default content
    -->
              <template v-slot:header>
                <h2>Beräkna bästa pris</h2>
                <span style="cursor: pointer" v-on:click="showModal = false">
                Tillbaka till kassan <i style="font-size: 18px; margin-left: 4px;" class="fa fa-times"></i>
                </span>
              </template>
              <template v-slot:body>
                <ShoppingCartCalculator
                  :inCart="[...cart]"
                  :inExcludeTax="excludeTax"
                >
                </ShoppingCartCalculator>
              </template>
            </Modal>
          </transition>
          <table
            class="
              ui
              sortable
              compact
              celled
              table
              small
              tablet
              stackable
              product-table
            "
          >
            <thead>
              <tr v-if="!isMobile()">
                <th class="no-sort"></th>
                <th>Produkt</th>
                <th>Artikelnr</th>
                <th>Period</th>
                <th>Pris</th>
                <th>Summa</th>
                <th>Antal produkter</th>
              </tr>
            </thead>
            <tbody>
              <CartItemTableRow
                v-for="item in cart"
                :key="item.cartItem.Id"
                :item="item"
                :removeItem="removeFromCart"
                :quantityChange="quantityChangeItem"
                :excludeTax="excludeTax"
              />
              <tr class="empty-cart">
                <td colspan="5">
                  <span style="color: #f18a65"
                    >Du skickar eller lämnar tillbaka sakerna när du är klar. Så
                    du behöver inte välja slutdatum för din hyra</span
                  >
                </td>
                <td colspan="2" class="right aligned">
                  <input
                    type="button"
                    style="background-color: white; color: #03564a"
                    name="emptyCart"
                    value="Töm varukorgen"
                    class="
                      button-rentor-second
                      remove-all-button
                      update-cart-button
                    "
                    @click="removeAllFromCart"
                    onclick="removeAllInCart()"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="summary-container">
          <div class="flex-just-between flex-items-end">
            <h2>Totalkostnad</h2>
            <div class="global-date-rental-container if-public">
              <label
                >Startdatum för hyresobjekt
                <i
                  id="tooltiprental"
                  class="fa fa-question-circle-o"
                  title="placeholdertitle"
                ></i
              ></label>

              <Datepicker
                style="max-width: 150px; margin-left: auto"
                :clearable="false"
                locale="sv-SE"
                cancelText="Avbryt"
                selectText="Välj"
                autoApply
                noToday
                :format="'yyyy-MM-dd'"
                :minDate="fromDate"
                :enableTimePicker="false"
                :modelValue="startDate"
                @update:modelValue="setStartDates"
              />
            </div>
          </div>
          <table
            class="ui table summary small unstackable"
            style="min-width: 320px"
          >
            <tbody>
              <tr>
                <td>
                  Klicka på miniräknaren för att visa en detaljerad
                  priskalkylator
                </td>
                <td class="right aligned">
                  <i
                    id="show-modal"
                    @click="showModal = true"
                    style="font-size: 22px; color: #f18a65; cursor: pointer"
                    class="fa fa-calculator"
                  ></i>
                </td>
              </tr>
              <tr class="main-row">
                <td class="cart-total-left top aligned">
                  <label>Totalkostnad hyra</label>
                  <br />
                  <small
                    ><i>{{ excludeTax ? "exk moms" : "inkl moms" }}</i></small
                  >
                </td>
                <td class="cart-total-right right aligned">
                  <span class="value-summary one-row-force"
                    >{{
                      Math.round(
                        orderTotals.rentalTotalPerDayExTaxes +
                          (excludeTax ? 0 : orderTotals.taxRentalPerDay)
                      )
                    }}
                    kr / {{ allLongTermPeriodReadValue }}</span
                  ><br />
                  <i v-if="!isAllRentalSamePeriod" style="font-size: 12px">
                    Priset visas per dag då alla hyresobjekt inte är valda på
                    samma period
                  </i>
                </td>
              </tr>

              <tr class="summary-details" :class="{ show: showRentalDetails }">
                <td colspan="2">
                  <table>
                    <tbody>
                      <tr class="summary-cost">
                        <td class="cart-total-left">
                          <label>Ordersumma hyresartiklar:</label>
                        </td>
                        <td class="right aligned one-row-force">
                          {{
                            orderTotals.rentalTotalPerDayExTaxes +
                            (excludeTax ? 0 : orderTotals.taxRentalPerDay)
                          }}
                          kr / {{ allLongTermPeriodReadValue }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>{{ excludeTaxText }} hyresartiklar:</label>
                        </td>
                        <td class="right aligned">
                          {{ orderTotals.taxRentalPerDay }} kr /
                          {{ allLongTermPeriodReadValue }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr class="show-more-row">
                <td colspan="2">
                  <div @click="showRentalDetails = !showRentalDetails">
                    {{ showRentalDetails ? "Dölj" : "Visa" }} detaljer
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="order-subtotal main-row">
                <td>
                  <label>Engångskostnader</label>
                  <br />
                  <small
                    ><i>{{ excludeTax ? "exk moms" : "inkl moms" }}</i></small
                  >
                </td>
                <td class="right aligned">
                  {{
                    Math.round(
                      orderTotals.salesTotalExTaxes +
                        (excludeTax ? 0 : orderTotals.taxSale)
                    )
                  }}
                  kr
                </td>
              </tr>

              <tr class="summary-details" :class="{ show: showSaleDetails }">
                <td colspan="2">
                  <table>
                    <tbody>
                      <tr class="summary-cost">
                        <td class="cart-total-left">
                          <label>Ordersumma försäljningsartiklar:</label>
                        </td>
                        <td
                          class="cart-total-right right aligned one-row-force"
                        >
                          <span class="value-summary"
                            >{{
                              orderTotals.salesTotalExTaxes +
                              (excludeTax ? 0 : orderTotals.taxSale)
                            }}
                            kr</span
                          >
                        </td>
                      </tr>
                      <tr class="tax-value">
                        <td class="cart-total-left">
                          <label
                            >{{ excludeTaxText }} försäljningsartiklar:</label
                          >
                        </td>
                        <td
                          class="cart-total-right right aligned one-row-force"
                        >
                          <span class="value-summary"
                            >{{ orderTotals.taxSale }} kr</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr class="show-more-row">
                <td colspan="2">
                  <div @click="showSaleDetails = !showSaleDetails">
                    {{ showSaleDetails ? "Dölj" : "Visa" }} detaljer
                  </div>
                </td>
              </tr>
              <tr class="shipping-cost">
                <td class="cart-total-left">
                  <label>Frakt:</label>
                  <br />
                  <small class="selected-shipping-method"
                    >{{ selectedShippingMethod }}<br /><i>inkl moms</i></small
                  >
                </td>
                <td class="cart-total-right one-row-force right aligned">
                  <span class="value-summary"
                    >{{ Math.round(shippingCost * 1.25) }} kr</span
                  >
                  <div>
                    <small
                      ><i
                        >Varav moms {{ Math.round(shippingCost * 0.25) }}</i
                      ></small
                    >
                  </div>
                </td>
              </tr>
              <tr class="summary-cost-min-deb" style="font-size: 0.8rem">
                <td class="cart-total-left">
                  <label>Minsta totala debitering ink moms</label>
                </td>
                <td class="cart-total-right right aligned one-row-force">
                  <span class="value-summary"
                    >{{ orderTotals.rentalTotalMinDebIncTaxes }} kr</span
                  >
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ShareCart :cart="cart"></ShareCart>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="checkout-attributes">
            <dl>
              <dt id="order_comment_label">
                <label class="text-prompt"> Orderkommentar (frivillig) </label>
              </dt>
              <dd id="order_comment_input_2">
                <textarea
                  id="order_comment"
                  name="order_comment"
                  v-model="orderComment"
                ></textarea>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div v-if="cart.length && !isInStore">
        <div>
          <h2 class="title">Leverans</h2>
        </div>
        <CheckoutShipping
          :onShippingChanged="onShippingChanged"
          :externalShippingCost="externalShippingCost"
          :selectedShippingMethod="fullshippingMethod"
          :excludeTax="excludeTax"
          :isOfferableShipping="externalShippingCost < 1"
          :sthlmIsOpen="stockholmIsOpen"
          :PickUpPointIsSelectedAndLocked="shippingSelectionDisabled"
        ></CheckoutShipping>
      </div>
    </form>
  </div>
</template>

<script>
import CartService from "../services/cart.service";
import CartItemTableRow from "../components/CartItemTableRow.vue";
import CheckoutShipping from "../components/CheckoutShipping.vue";
import TaxSelector from "../components/TaxSelector.vue";
import Modal from "../components/Modal.vue";
import ShoppingCartCalculator from "../components/ShoppingCartCalculator.vue";
import ShareCart from "../components/ShareCart.vue";

import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

const cartService = new CartService();

export default {
  name: "CheckoutMainContainer",
  components: {
    CartItemTableRow,
    Datepicker,
    CheckoutShipping,
    TaxSelector,
    Modal,
    ShoppingCartCalculator,
    ShareCart,
  },

  mounted() {
    const vm = this;
    window.addEventListener("added_to_cart", function () {
      cartService.getCart().then(function (response) {
        var hasRequieredProduct =
          response.data.cart.length - vm.cart.length > 1;
        if (hasRequieredProduct) {
          vm.cart.unshift(response.data.cart[1]);
          vm.cart.unshift(response.data.cart[0]);
        } else {
          vm.cart.unshift(response.data.cart[0]);
        }
      });
    });

    window.onbeforeunload = function (evt) {
      if (vm.hasClickedAddOrder) {
        return;
      }
      cartService.setCart(vm.cart, "");
    };

    window.addEventListener("add_order", function () {
      vm.hasClickedAddOrder = true;
      vm.setCart();
    });
  },
  data() {
    return {
      cart: [],
      stockholmIsOpen: false,
      orderTotalsOrigin: {},
      showRentalDetails: false,
      showSaleDetails: false,
      startDate: new Date(),
      fromDate: new Date(),
      shippingCost: 0,
      selectedShippingMethod: "",
      fullshippingMethod: {},
      orderComment: "",
      isInStore: false,
      hasClickedAddOrder: false,
      excludeTax: true,
      showModal: false,
      shippingSelectionDisabled: false
    };
  },
  computed: {
    excludeTaxText: function () {
      return this.excludeTax ? "Moms" : "Varav moms";
    },
    externalShippingCost: function () {
      var cart;
      if (this.cart.length) cart = this.cart;
      var numberArray = cart.map(
        (item) => item.product.AdditionalShippingCharge
      );
      var additionalShippingCostSum = Math.max(...numberArray) * 2;
      if(additionalShippingCostSum == 0) return 198;
      
      var additionalShippingCostSumAnyIsOfferable = Math.min(...numberArray) * 2;
     
      if(additionalShippingCostSumAnyIsOfferable < 0) {
        return 0;
      }
        return additionalShippingCostSum

    },
    orderTotals: function () {
      if (this.cart.length > 0) {
        var rentalTotalPerDayExTaxes = 0;
        var rentalTotalMinDebIncTaxes = 0;
        var salesTotalExTaxes = 0;
        this.cart.forEach((item) => {
          if (item.cartItem.RentalStartDateUtc !== null) {
            rentalTotalPerDayExTaxes += this.mapActivePrice(item);
            rentalTotalMinDebIncTaxes += this.mapActivePrice(item, false) * 1.25;

               if(item.cartItem.RentalEndDateUtc !== null) {
         
let daysBetween = (date_1, date_2) =>{
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
};
         rentalTotalPerDayExTaxes = rentalTotalPerDayExTaxes / daysBetween(new Date(item.cartItem.RentalEndDateUtc), new Date(item.cartItem.RentalStartDateUtc));
         
        }

          } else {
            salesTotalExTaxes += item.product.Price * item.cartItem.Quantity;
          }
        });
        var taxSale = Math.round(salesTotalExTaxes * 0.25);
        return {
          ...this.orderTotalsOrigin,
          excludeTax: this.excludeTax,
          rentalTotalPerDayExTaxes: Math.round(rentalTotalPerDayExTaxes),
          taxRentalPerDay: Math.round(rentalTotalPerDayExTaxes * 0.25),
          taxSale: taxSale,
          rentalTotalMinDebIncTaxes: Math.round(
            rentalTotalMinDebIncTaxes +
              salesTotalExTaxes +
              taxSale +
              (this.shippingCost * 1.25)
          ),
          salesTotalExTaxes: Math.round(salesTotalExTaxes),
        };
      }
      return this.orderTotalsOrigin;
    },
    isAllRentalSamePeriod: {
      get: function () {
        var rentalCart = this.cart?.filter(
          (c) => c.cartItem.RentalStartDateUtc !== null
        );
        return rentalCart.every(
          (item) =>
            item.cartItem.LongTermRentPriceIndex.toString() ===
            rentalCart[0]?.cartItem.LongTermRentPriceIndex.toString()
        );
      },
      set: function (newValue) {
        // this.isAllRentalSamPeriod = newValue
      },
    },
    allLongTermPeriodReadValue: {
      get: function () {
        var rentalCart = this.cart?.filter(
          (c) => c.cartItem.RentalStartDateUtc !== null
        );
        return this.isAllRentalSamePeriod
          ? rentalCart[0]?.cartItem.LongTermRentPriceIndex.toString() === "-1"
            ? "dag"
            : rentalCart[0]?.cartItem.LongTermRentPriceIndex.toString() === "3"
            ? "vecka"
            : "månad"
          : "dag";
      },
      set: function (newValue) {},
    },
  },

  created() {
    this.initCart();
  },
  methods: {
    setStartDates(val) {
      this.startDate = val;
      this.cart.forEach((item) => {
        if (!item.cartItem.RentalStartDateUtc || !!item.cartItem.RentalEndDateUtc) return;
  
        item.cartItem.RentalStartDateUtc = val;
      });
    },
    taxIsChanged(excludeTax) {
      this.excludeTax = excludeTax;
      if (excludeTax) {
        $('label[for="BillingNewAddress_OrgNumber"]').html("Organisationsnummer:");
        $('label[for="BillingNewAddress_Company"]').show();
        $('input#BillingNewAddress_Company').show();
        //$('input#BillingNewAddress_Company').val(null);
        $('input#BillingNewAddress_Company').closest('.inputs').show();
        $('input#BillingNewAddress_Company').attr('required', 'required');
      } 
      else {
        $('label[for="BillingNewAddress_OrgNumber"]').html("Personnummer:");
        $('label[for="BillingNewAddress_Company"]').hide();
        $('input#BillingNewAddress_Company').hide();
        $('input#BillingNewAddress_Company').val('Privat');
        $('input#BillingNewAddress_Company').closest('.inputs').hide();
        $('input#BillingNewAddress_Company').attr('required', null);
      }
    },

    setCart() {
      cartService
        .setCart(this.cart, this.orderComment)
        .then(function (response) {
          if (response.data.success === 1) {
            if (typeof addOrderAdminWay === "function") {
              addOrderAdminWay();
            } else {
              Billing.save();
            }
          } else {
            alert(
              "Något gick fel, uppdatera sidan och försök igen eller ring till oss så löser vi problemet"
            );
          }
        });
    },
    initCart() {
      cartService.getCart().then((data) => {
        this.taxIsChanged(data.data.excludeTax);
        this.isInStore = data.data.isInStore;
        this.orderTotalsOrigin = data.data.totals;
        this.selectedShippingMethod = data.data.selectedShippingOption.Name;
        this.fullshippingMethod = data.data.selectedShippingOption;
        this.stockholmIsOpen = data.data.sthlmIsOpen;
        data.data.cart.forEach((item) => {
          this.setPriceProps(item);
          item.attributes = data.data.individualsByShoppingCartId[
            item.cartItem.Id
          ]?.sort((i) => i.indexOf(item.product.Sku) > -1);
          this.cart.push(item);
        });
        this.calculateAdditionalShipping(data.data.cart);
        let shippingCostCalc = data.data.selectedShippingOption.Name.indexOf("rakt") > -1
            ? this.externalShippingCost
            : data.data.selectedShippingOption.Rate;
        this.shippingCost = shippingCostCalc > -1 ? shippingCostCalc : 0;
          this.shippingSelectionDisabled = data.data.cart.some(function(c){
              return !!c.cartItem.AttributesXml && (c.cartItem.AttributesXml.indexOf('Stockholm') > -1 || c.cartItem.AttributesXml.indexOf('Hisings Backa') > -1); 
          })
      });
    },
    calculateAdditionalShipping(cart) {
      if (this.cart.length) cart = this.cart;
      var numberArray = cart.map(
        (item) => item.product.AdditionalShippingCharge
      );
      var additionalShippingCostSum = Math.max(...numberArray) * 2;
      var additionalShippingCostSumAnyIsOfferable = Math.min(...numberArray) * 2;
     
      if(additionalShippingCostSumAnyIsOfferable < 0) {
      this.externalShippingCost  = additionalShippingCostSumAnyIsOfferable;
      }
      else{
        this.externalShippingCost = additionalShippingCostSum;

      };

    },
    onShippingChanged(newMethod) {
      this.shippingCost = newMethod.cost;
      this.selectedShippingMethod = newMethod.textType;
      cartService.setShippingOptionSilent(newMethod.currentTarget);
    },
    removeFromCart(item) {
      this.cart = this.cart.filter(
        (c) =>
          c.cartItem.Id !== item.cartItem.Id &&
          c.cartItem.RequirerItemId !== item.cartItem.Id
      );
    },
    quantityChangeItem(item, increase) {
      this.cart
      .filter((c) => c.cartItem.RequirerItemId === item.cartItem.Id)
      .forEach((c) => {
        c.cartItem.Quantity = increase
        ? c.cartItem.Quantity + 1
        : c.cartItem.Quantity - 1;
      });
      const confirmButton = document.getElementById("confirm-order-next-step-button");
      if (confirmButton) {
      const vm = this;
      setTimeout(function(){
        console.log(vm.cart);
        confirmButton.disabled = true;
        // Call the asynchronous setCart() function and handle it with then
          cartService.setCart(vm.cart, "").then(() => {
            confirmButton.removeAttribute('disabled');
        });
        }, 100);
      }
    },
    setPriceProps(item) {
      var totalPrice =
        item.product.Price *
        item.cartItem.Quantity *
        (this.excludeTax ? 1 : 1.25);
      var unitPrice = item.product.Price * (this.excludeTax ? 1 : 1.25);
      var price = {
        unitPrice: unitPrice,
        totalPrice: totalPrice,
      };
      item.price = price;
    },
    removeAllFromCart() {
      const vm = this;
      cartService.emptyCart().then(function (response) {
        if (response.data.success === 1) {
          vm.cart = [];
          vm.initCart();
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-root {
  margin-top: 50px;
}
.taxRoot {
  position: absolute;
  transform: translate(0, -100%);
}
.isMobile.v-root {
  margin-top: 0;
}
.ui.table tr.summary-details {
  display: none !important;
}
.ui.table tr.summary-details.show {
  display: table-row !important;
}
.ui.table .show-more-row td {
  cursor: pointer;
  border-top: none;
  text-align: center;
  padding: 4px 0;
}

.ui.table tbody + tbody .summary-details tr:first-child td {
  border-top: none;
}
.flex-just-center > div:first-of-type {
  margin-right: 7px;
}
.isMobile .flex-just-center > div:first-of-type {
  margin-right: 0;
}
.isInStore .summary-container {
  margin-top: calc(-1em - 25px);
}
.summary-container {
  flex: 0 0 340px;
  margin-top: calc(-1em - 59px);
}
.isMobile .summary-container {
  margin-top: 20px;
}
.product-table {
  align-self: baseline;
  margin-top: 0;
}
.isInStore .margin-summary-desktop {
  margin-top: -1em;
}

tr.empty-cart {
  padding: 2px 0 !important;
}
.one-row-force {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.isMobile .flex-just-center {
  flex-wrap: wrap;
}
.summary-details > td {
  box-shadow: inset 0px -6px 6px 0px #70707085;
}

.isInStore .if-public {
  display: none;
  width: 0px;
  height: 0px;
  padding: 0px;
}
.isInStore .if-public * {
  display: none;
  width: 0px;
  height: 0px;
  padding: 0px;
}
.one-row-force,
.right.aligned {
  font-size: 1.05em;
  color: #000;
}
.ui.celled.table input[type="button"] {
  padding: 10px;
  text-align: center;
  font-size: 13px;
  border-radius: 4px;
  text-transform: none;
}
</style>
