<template>
<div>
  <component :is="checkoutContainerComponent"/>
</div>
</template>

<script>
import CheckoutMainContainer from './components/CheckoutMainContainer.vue'
import CheckoutMainContainerFlyout from './components/CheckoutMainContainerFlyout.vue'

export default {
  name: 'App',
  components: {
    CheckoutMainContainer,
    CheckoutMainContainerFlyout
  },
  computed: {
    checkoutContainerComponent() {
      return window.location.href.includes('onepagecheckout')
        ? 'CheckoutMainContainer'
        : 'CheckoutMainContainerFlyout'
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #03564a!important;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
}

</style>
