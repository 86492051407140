<template>
 <form id="co-shipping-method-form" action="">
<div class="section shipping-method" id="shipping-methods-form">
<ul class="method-list flex-just-between flex-items-center" v-if="sthlmIsOpen">
  <span>
  <li :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Hämtas vid Rentor - Västberga___Pickup.PickupInStore'}">
    <label for="shippingoption_0">
      <div class="method-name" :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Hämtas vid Rentor - Västberga___Pickup.PickupInStore'}">
        <input style="opacity:0;width:0" @change="shippingChanged($event)" id="shippingoption_0" type="radio" name="shippingoption" class="shippingOptionInput" :checked="selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Hämtas vid Rentor - Västberga___Pickup.PickupInStore'" data-shippingcost="0" value="Hämtas vid Rentor - Västberga___Pickup.PickupInStore"> Hämtas vid Rentor - Västberga <br> (0 kr)</div>
        </label>
        <div class="method-description">Vår butik i Västberga. Vretensborgsvägen 5</div>
    </li>
     <li :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Hämtas vid Rentor - Hisings Backa___Pickup.PickupInStore'}">
    <label for="shippingoption_1">
      <div class="method-name" :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Hämtas vid Rentor - Hisings Backa___Pickup.PickupInStore'}">
        <input style="opacity:0;width:0" @change="shippingChanged($event)" id="shippingoption_1" type="radio" name="shippingoption" class="shippingOptionInput" :checked="selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Hämtas vid Rentor - Hisings Backa___Pickup.PickupInStore'" data-shippingcost="0" value="Hämtas vid Rentor - Hisings Backa___Pickup.PickupInStore"> Hämtas vid Rentor - Hisings Backa <br> (0 kr)</div>
        </label>
        <div class="method-description">Vår butik i Hisings Backa. Backa Strandgata 12</div>
    </li>
  </span>
    <li :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Utkörning storgöteborg___Shipping.FixedByWeightByTotal'}">
      <label for="shippingoption_2">
        <div class="method-name" :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Utkörning storgöteborg___Shipping.FixedByWeightByTotal'}">
          <input style="opacity:0;width:0" @change="shippingChanged($event)" id="shippingoption_2" :checked="selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Utkörning storgöteborg___Shipping.FixedByWeightByTotal'" type="radio" name="shippingoption" class="shippingOptionInput" data-shippingcost="299" value="Utkörning storgöteborg___Shipping.FixedByWeightByTotal"> Utkörning storgöteborg <br> ({{excludeTax ? 299 : Math.round(299 * 1.25)}} kr)
        </div>
      </label>
      <div class="method-description">Utkörning och hämtning inom storgöteborg</div>
    </li>
    <li :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Frakt___Shipping.FixedByWeightByTotal'}">
      <label for="shippingoption_3">
        <div class="method-name" :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Frakt___Shipping.FixedByWeightByTotal'}">
          <input style="opacity:0;width:0"  @change="shippingChanged($event)" :checked="selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Frakt___Shipping.FixedByWeightByTotal'" id="shippingoption_3" type="radio" name="shippingoption" class="shippingOptionInput" value="Frakt___Shipping.FixedByWeightByTotal" :data-shippingcost="externalShippingCost"> Frakt <br> ({{externalShippingCost}} kr)
          </div>
          </label>
          <div class="method-description">Frakt med speeditör</div>
    </li>
  </ul>
  <ul v-else class="method-list flex-just-between flex-items-center">
  
  <li :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Hämtas vid Rentor - Hisings Backa___Pickup.PickupInStore'}">
    <label for="shippingoption_0">
      <div class="method-name" :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Hämtas vid Rentor - Hisings Backa___Pickup.PickupInStore'}">
        <input style="opacity:0;width:0" @change="shippingChanged($event)" id="shippingoption_0" type="radio" name="shippingoption" class="shippingOptionInput" :checked="selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Hämtas vid Rentor - Hisings Backa___Pickup.PickupInStore'" data-shippingcost="0" value="Hämtas vid Rentor - Hisings Backa___Pickup.PickupInStore"> Hämtas vid Rentor - Hisings Backa <br> (0 kr)</div>
        </label>
        <div class="method-description">Vår butik i hisingsbacka. Backa Strandgata 12</div>
    </li>
    <li :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Utkörning storgöteborg___Shipping.FixedByWeightByTotal'}">
      <label for="shippingoption_1">
        <div class="method-name" :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Utkörning storgöteborg___Shipping.FixedByWeightByTotal'}">
          <input style="opacity:0;width:0" @change="shippingChanged($event)" id="shippingoption_1" :checked="selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Utkörning storgöteborg___Shipping.FixedByWeightByTotal'" type="radio" name="shippingoption" class="shippingOptionInput" data-shippingcost="299" value="Utkörning storgöteborg___Shipping.FixedByWeightByTotal"> Utkörning storgöteborg <br> ({{excludeTax ? 299 : Math.round(299 * 1.25)}} kr)
        </div>
      </label>
      <div class="method-description">Utkörning och hämtning inom storgöteborg</div>
    </li>
    <li :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Frakt___Shipping.FixedByWeightByTotal'}">
      <label for="shippingoption_2">
        <div class="method-name" :class="{'active': selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Frakt___Shipping.FixedByWeightByTotal'}">
          <input style="opacity:0;width:0"  @change="shippingChanged($event)" :checked="selectedShippingMethod.Name + '___'+ selectedShippingMethod.ShippingRateComputationMethodSystemName === 'Frakt___Shipping.FixedByWeightByTotal'" id="shippingoption_2" type="radio" name="shippingoption" class="shippingOptionInput" value="Frakt___Shipping.FixedByWeightByTotal" :data-shippingcost="externalShippingCost"> Frakt <br>
          <span v-if="!isOfferableShipping"> ({{excludeTax ? externalShippingCost : Math.round(externalShippingCost * 1.25)}} kr)</span>
          <span v-else> (Priset för frakt offereras pga otympligt gods i din beställning)</span>
          </div>
          </label>
          <div class="method-description">Frakt med speeditör</div>
    </li>
  </ul>
</div>
 </form>
</template>

<script>
import { readonly } from '@vue/reactivity';

export default {
   
  name: 'CheckoutShipping',
  props:{
      onShippingChanged: Function,
      externalShippingCost: Number,
      isOfferableShipping: Boolean,
      selectedShippingMethod: Object,
      sthlmIsOpen: Boolean,
      excludeTax: Boolean,
      PickUpPointIsSelectedAndLocked: Boolean
  },
  mounted(){
    this.shippingMethodInitCheck(this.selectedShippingMethod.ShippingRateComputationMethodSystemName);
  },
    data() {
      return {
        
      }
    },
    
    methods: {
      shippingChanged(selectedShippingMethod) {
        var input = selectedShippingMethod.currentTarget;
        if(input.className.indexOf('disabled') > -1) return;
        this.onShippingChanged({cost: input.dataset.shippingcost, textType: input.value.split('_')[0], currentTarget: input });
      },
      shippingMethodInitCheck(shippingMethodName){
        if (shippingMethodName.indexOf('PickupInStore') < 0) {
                $('#checkout-step-shipping').removeClass('inactive');
            }
            else {
                $('#checkout-step-shipping').addClass('inactive');
            }
            ShippingMethod.saveUrl = '/checkout/OpcSaveShippingMethod';
            ShippingMethod.form = '#co-shipping-method-form';
            ShippingMethod.saveSilent();
            if(this.PickUpPointIsSelectedAndLocked) {
              $('#shipping-methods-form input').addClass('disabled');
              $('#shipping-methods-form input').closest('label').addClass('disabled');
            }
      }      
    }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.isMobile .method-list {
  flex-wrap: wrap;
  justify-content: center;
}
label.disabled, label.disabled .method-name {
  opacity: .8;
  cursor: not-allowed;
}
</style>
